<template>
  <div class="content flex-column">
    <div class="flex-space-between">
      <div class="operation flex-center-v">
        <el-input v-model="searchTaiZhangForm.vendor" placeholder="请输入吊篮厂商" size="small" style="width:140px;margin-right:15px;"></el-input>
        <el-date-picker
            v-model="searchTaiZhangForm.time"
            value-format="yyyy-MM-dd"
            size="small"
            :clearable="false"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="search">
        <el-button type="primary" size="small" style="width:80px;" @click="exportTaiZhang">导出</el-button>
        <el-button type="primary" size="small" style="width:80px;" @click="searchTaiZhang">查询</el-button>
      </div>
    </div>
    <div class="cont flex-1-auto">
      <el-table
          :data="tableDatas"
          border
          height="88%"
          :span-method="objectSpanMethod"
          style="width: 100%; margin-top: 20px">
        <el-table-column
            prop="index"
            label="序号"
            width="60">
          <template slot-scope="scope">
            <div>
              {{scope.row.index}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="id"
            width="100"
            label="检查部位">
          <template slot-scope="scope">
            <div>
              {{scope.row.name}}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="检查项"
            align="left"
        >
          <template slot-scope="scope">
            <div>
              {{scope.row.checkPartName}}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="65" v-for="(item, index) in tableHeaderArrayD" :key="index" :label="item" :prop="tableHeaderArrayD[index]">
          <template slot-scope="scope">
            <div>
              {{scope.row[tableHeaderArrayD[index]]==1?'√':scope.row[tableHeaderArrayD[index]]==0?'×':''}}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="borderTips">
        整改情况:表中“记录”栏中，用“√”表示完好，“×”表示有问题。提升机和运行试验检查，应在前面项目检查完毕，并整改合格后再进行。检查项目有问题的，立即进行整改
      </div>
    </div>
  </div>
</template>

<script>
import apis from "@/views/menus/api/security-check"
import {downloadFile,getDate} from '@/utils/utils'
import BaseURL from '@/apis/baseurl'
import {mapGetters} from "vuex";
export default {
  name: "InspectionLedger",

  components: {},

  props: {},

  data() {
    return {
      tableDatas: [],
      searchTaiZhangForm:{
        pageCurrent: 1,
        pageSize: 10,
        time:''
      },
      tableHeaderArrayD:[],
    }
  },

  computed: {
    ...mapGetters(['companyId', 'optionItem'])
  },

  watch: {
    companyId() {
      this.initTaiZhang()
    }
  },

  mounted() {
    this.searchTaiZhangForm.time =getDate(new Date()).fullDate
    this.tableHeaderArrayD= this.getEveryD()
    this.initTaiZhang()
  },

  methods: {
    // init() {
    // }
    initTaiZhang(){
      // let that = this
      if (!this.companyId) return this.initTaiZhang()
      const params = {
        departmentCode:this.optionItem.departmentCode,
        vendor:this.searchTaiZhangForm.vendor,
        time:this.searchTaiZhangForm.time,
      }
      apis.findAllCheckRecord(params).then(res=>{
        const array = []
        for (let i = 0;i<res.checkPartDtoList.length;i++){
          for (let j = 0;j<res.checkPartDtoList[i].checkDtoList.length;j++){
            array.push({
              index:i+1,
              name:res.checkPartDtoList[i].name,
              checkPartName:res.checkPartDtoList[i].checkDtoList[j].name
            })
          }
        }
        this.tableDatas = array
        this.tableHeaderArrayD = res.recordDtoList.map(i=>{return i.name})
        this.insertData(res.recordDtoList)
        // that.TaiBantableData = res
      })
    },
    // TODO
    getEveryD (){
      const dayArray = [];
      for (let i = 1; i <= 6; i++){
        dayArray.push( "吊篮" + i);
      }
      return dayArray;
    },
    insertData(recordDtoList){
      // 首先循环返回的所有数据列表，也就是所有吊篮的数量，每个吊篮的第一行就是里面的一跳数据
      // 如果吊篮的name和checkpartname和this.table data里面的数据都一样的话，namestatus直接赋值
      // console.log(this.tableDatas)
      for (let m = 0; m < this.tableDatas.length; m++){
        for (let i = 0;i<recordDtoList.length;i++) {
          if (recordDtoList[i].checkPartList.length>0){
            for (let j = 0; j < recordDtoList[i].checkPartList.length; j++) {
              for (let k = 0; k < recordDtoList[i].checkPartList[j].checkDtoList.length; k++) {
                if (this.tableDatas[m].name == recordDtoList[i].checkPartList[j].name&&this.tableDatas[m].checkPartName == recordDtoList[i].checkPartList[j].checkDtoList[k].name) {
                  this.tableDatas[m][recordDtoList[i].name] = recordDtoList[i].checkPartList[j].checkDtoList[k].status
                }
              }
            }
          }
        }
      }
    },
    searchTaiZhang(){
      this.initTaiZhang()
    },
    mergeComon(id, rowIndex) { // 合并单元格
      var idName = this.tableDatas[rowIndex][id]
      if (rowIndex > 0) {
        // eslint-disable-next-line eqeqeq
        if (this.tableDatas[rowIndex][id] != this.tableDatas[rowIndex - 1][id]) {
          let i = rowIndex; let num = 0
          while (i < this.tableDatas.length) {
            if (this.tableDatas[i][id] === idName) {
              i++
              num++
            } else {
              i = this.tableDatas.length
            }
          }
          return {
            rowspan: num,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 1
          }
        }
      } else {
        let i = rowIndex;
        let num = 0
        while (i < this.tableDatas.length) {
          if (this.tableDatas[i][id] === idName) {
            i++
            num++
          } else {
            i = this.tableDatas.length
          }
        }
        return {
          rowspan: num,
          colspan: 1
        }
      }
    },
    objectSpanMethod({rowIndex,columnIndex }) {
      // 现在需要做的，也就是把检查部位相同的列进行合并，比如都是电器系统的话，就把同为电器系统的合并掉
      // 判断列的属性
      switch (columnIndex) {
        case 0:
          return this.mergeComon('index', rowIndex)
        case 1:
          return this.mergeComon('name', rowIndex)
        case 2:
          return this.mergeComon('checkPartName', rowIndex)
          // eslint-disable-next-line no-unreachable
          break
      }
    },
    exportTaiZhang(){
      if (this.searchTaiZhangForm.time){
        const params = {
          departmentCode:this.optionItem.departmentCode,
          vendor:this.searchTaiZhangForm.vendor||'',
          time: this.searchTaiZhangForm.time
        }
        const url = BaseURL + `/admin/public/exportCheckRecord?departmentCode=${params.departmentCode}&time=${params.time}&vendor=${params.vendor}`
        downloadFile(url, {}, '吊篮日检报表.xls')
      }else{
        this.$message.error('日期不能为空')
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
